import { SUPABASE_STORAGE_URL } from "lib/globals";
import Image from "next/image";
import { EventExtended } from "types/helpers";

interface BannerProps {
  event: EventExtended;
}

const BannerPicture: React.FC<BannerProps> = ({ event }) => {
  let { event_image = [], title } = event;

  const imgSrc =
    event_image.length > 0
      ? `${SUPABASE_STORAGE_URL}/${event_image[0].path}?updated_at=${event_image[0].updated_at}`
      : "";

  return (
    <div className="relative mb-gr-5 mt-gr-7">
      <div className="relative aspect-video">
        <Image src={imgSrc} fill alt="" className="object-cover" />
      </div>
      {/* <img src={imgSrc} alt={title} className="w-full" /> */}

      {/* gradient */}
      <div className="absolute top-0 z-10 w-full h-gr-8 bg-gradient-to-t from-transparent to-grayDarkest"></div>
      <div className="absolute z-10 w-full -bottom-0 h-gr-8 bg-gradient-to-b from-transparent to-grayDarkest"></div>
    </div>
  );
};

export default BannerPicture;
