import Banner from "components/banner/Banner";
import LayoutHeaderSection from "components/layout/HeaderSection";
import Section from "components/Section";
import { getEvents } from "lib/api/events";
import { getSortedEvents } from "lib/event-helpers";
import { useQuery } from "react-query";
import { EventExtended } from "types/helpers";

const HomeBannersSection: React.FC = () => {
  const {
    data: events,
    error,
    isError,
    isLoading,
  } = useQuery<EventExtended[]>("events", getEvents);

  if (isError) return <p>Error loading events</p>;
  if (isLoading) return <p>...Loading</p>;

  const { currentEvents = [], pastEvents = [] } = getSortedEvents(
    events?.filter((event) => event.active)
  );

  const featuredEvents = currentEvents.filter((e) => e.featured);

  return (
    <Section>
      {featuredEvents.map((featuredEvent) => (
        <Banner key={featuredEvent.id} event={featuredEvent} />
      ))}
      {currentEvents
        .filter((e) => !e.featured)
        .map((currentEvent) => (
          <Banner key={currentEvent.id} event={currentEvent} />
        ))}
      {/* <BannerNewGeneric
        bannerImage="https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/event-banners/LoudHomepage1800x800.jpeg"
        title="Music Education Goes Virtual"
        buttonText="Learn More"
        url="/loud-sweden"
      /> */}

      <LayoutHeaderSection
        title="Recent Events"
        subhead
        description="Watch featured recent events with top artists"
      />

      <div className="grid grid-cols-1 md:grid-cols-2">
        {pastEvents.slice(0, 10).map((pastEvent) => (
          <Banner key={pastEvent.id} event={pastEvent} />
        ))}
      </div>
    </Section>
  );
};

export default HomeBannersSection;
