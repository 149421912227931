import BannerPicture from "components/banner/BannerPicture";
import FadeIn from "components/FadeIn";
import { motion } from "framer-motion";
import { formatEventDate, getEventStates } from "lib/event-helpers";
import Link from "next/link";
import { EventExtended } from "types/helpers";

interface BannerProps {
  event: EventExtended;
}

const Banner: React.FC<BannerProps> = ({ event }) => {
  let { event_date, id, title } = event;

  const eventStates = getEventStates(event);

  const buttonText = !event_date
    ? "More Info"
    : eventStates.isPast
    ? "Watch Now"
    : "Get Tickets";

  return (
    <FadeIn Component={motion.div} transitionDuration={0.5}>
      <>
        <div className="mb-gr-8">
          <BannerPicture event={event} />

          <div className="container relative flex flex-col items-center w-full text-center md:items-start md:text-left mb-gr-7 md:ml-gr-6 xl:ml-gr-7">
            <div className="w-grp-6">
              <motion.h2
                initial={{ x: -100 }}
                whileInView={{ x: 0 }}
                className="pb-0 text-gr-6 md:text-gr-7 leading-gr-6 md:leading-gr-7 mb-gr-3 md:mb-gr-3"
              >
                <span style={{ textShadow: "2px 2px 2px black" }}>{title}</span>
              </motion.h2>
              <p
                className="pb-0 mb-gr-5 md:mb-gr-6 text-gr-5 md:text-gr-6"
                style={{ textShadow: "1px 1px 2px black" }}
              >
                {formatEventDate(event.event_date)}
              </p>
              <Link href={`/events/${id}`} className="btn white">
                {buttonText}
              </Link>
            </div>
          </div>
        </div>
      </>
    </FadeIn>
  );
};

export default Banner;
