import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { getTotalOrderAmount } from "lib/order-helpers";
import Stripe from "stripe";
import { LineItem, type Order } from "types";
import { DBEventDate, EventExtended } from "types/helpers";

import { SUPABASE_STORAGE_URL } from "./globals";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

const stripe = new Stripe(process.env.STRIPE_SECRET_KEY!, {
  apiVersion: "2022-11-15",
});

export type EventOrderCounts = {
  [key: string]: {
    count: number;
    grossRevenue: number;
  };
};

function formatEventDate(eventDates: DBEventDate[] = []) {
  const formatString = `dddd MMM D, YYYY - h:mm A CT`;

  if (eventDates.length === 1) {
    return dayjs(eventDates[0].event_timestamp)
      .tz("America/Chicago")
      .format(formatString);
  } else if (eventDates.length > 1) {
    return eventDates.map((ed) => dayjs(ed.event_timestamp)).join(", ");
  } else {
  }
  return "";
}

function getEventImageSrc(event: EventExtended) {
  try {
    const { event_image = [] } = event;
    const imgSrc =
      event_image.length > 0
        ? `${SUPABASE_STORAGE_URL}/${event_image[0].path}?updated_at=${event_image[0].updated_at}`
        : "";
    return imgSrc || "/images/ZL_Circle.png";
  } catch (err) {
    console.log("err", err);
    return "/images/ZL_Circle.png";
  }
}

/**
 * Create a map helper object which returns an object
 * whose keys are the event id and value is order count
 */
function getEventOrderCounts(orders: Partial<Order>[]): EventOrderCounts {
  let countMap: EventOrderCounts = {};

  orders.forEach((order) => {
    const eventId = order.event_ids![0];
    if (!countMap[eventId]) {
      countMap[eventId] = {
        count: 1,
        grossRevenue: getTotalOrderAmount(order),
      };
    } else {
      countMap[eventId].count++;
      countMap[eventId].grossRevenue =
        countMap[eventId].grossRevenue + getTotalOrderAmount(order);
    }
  });
  return countMap;
}

async function getEventProducts(eventId: string) {
  const { data: products } = await stripe.products.search({
    query: `active:\'true\' AND metadata[\'event_id\']:\'${eventId}\'`,
  });
  return products;
}

function getEventStates(event?: EventExtended) {
  if (!event) return { isCurrent: false, isPast: false };

  const timestamp = event.event_date[0].event_timestamp;
  const isCurrent =
    timestamp && dayjs.utc(timestamp).isSameOrAfter(dayjs(), "day");
  const isPast = timestamp && dayjs(timestamp).isBefore(dayjs());

  return { isCurrent, isPast };
}

const getSortedEvents = (events?: EventExtended[]) => {
  if (!events) return { currentEvents: [], pastEvents: [] };

  const sortedEvents = events.sort((a, b) => {
    if (a.event_date.length === 0 || b.event_date.length === 0) return 0;

    const dateA = a.event_date[0].event_timestamp;
    const dateB = b.event_date[0].event_timestamp;

    if (!dateA || !dateB) return 0;
    return dateA > dateB ? -1 : 1;
  });

  const currentEvents: EventExtended[] = [];
  const pastEvents: EventExtended[] = [];

  sortedEvents.forEach((e) => {
    const eventDate = e.event_date[0];
    if (
      eventDate &&
      dayjs.utc(eventDate.event_timestamp).isSameOrAfter(dayjs.utc(), "day")
    ) {
      currentEvents.push(e);
    } else {
      pastEvents.push(e);
    }
  });

  const sortedCurrentEvents = currentEvents.reverse();

  return { currentEvents: sortedCurrentEvents, pastEvents };
};

const getProductOrdersCount = (productId: string, orders: Order[]) => {
  let count = 0;
  orders.forEach((order) => {
    const lis = order.line_items as LineItem[];
    lis.forEach((li) => {
      if (li.price?.product === productId) {
        count++;
      }
    });
  });
  return count;
};

export {
  formatEventDate,
  getEventImageSrc,
  getEventOrderCounts,
  getEventProducts,
  getEventStates,
  getProductOrdersCount,
  getSortedEvents,
};
