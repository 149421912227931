import FadeIn from "components/FadeIn";
import HomeBannersSection from "components/home/BannersSection";
import HomeSliderSection from "components/home/SliderSection";
import Layout from "components/layout/Layout";
import Section from "components/Section";
import { motion, useScroll, useTransform } from "framer-motion";
import { NextPage } from "next";
import Image from "next/image";
import logo from "public/images/ZL_Logo.png";

const MotionImage = motion(Image);

const IndexPage: NextPage = () => {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 0.05], ["0%", "50%"]);

  return (
    <Layout title="Live streams for independent venues and artists - Zuma Live">
      <main className="flex-1">
        <h1 className="sr-only">
          Zuma Live streams for independent venues and artists - Zuma Live
        </h1>

        <motion.section className="relative h-screen overflow-hidden text-center bg-transparent text-offWhite">
          <motion.video
            style={{ y }}
            className="absolute top-0 bottom-0 left-0 right-0 object-cover min-w-full min-h-full"
            autoPlay
            loop
            muted
            playsInline
            preload="metadata"
          >
            <source src="/ZumaVisuals.mp4" type="video/mp4"></source>
          </motion.video>
          <div className="relative flex flex-col items-center justify-center h-screen text-center">
            <MotionImage
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.5,
                  duration: 1,
                },
              }}
              src={logo}
              alt="Zuma Live logo"
              className="w-64 md:w-80 xl:w-96"
            />
            <motion.p
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 1,
                  duration: 1,
                },
              }}
              className="max-w-lg mx-auto font-light leading-normal text-gray-400 pt-gr-7 text-gr-5 md:text-base lg:text-xl"
            >
              Connecting independent music venues and creators with the world.
              Ran by artists, for artists.
            </motion.p>
          </div>
        </motion.section>

        <HomeSliderSection />

        <HomeBannersSection />

        <Section>
          <FadeIn Component={motion.div}>
            <div className="container bg-grayDark text-offWhite py-gr-6">
              <div className="items-center justify-center gr-row">
                <div className="gr-col">
                  <p className="text-xl font-bold text-center md:text-2xl">
                    Want to produce or host an event?
                  </p>
                </div>
                <div className="flex justify-center gr-col">
                  <span>
                    {/* <Obfuscate
                      email="info@zuma.live"
                      headers={{
                        bcc: "adam@zuma.live",
                        subject: "Contact Us",
                        body: "",
                      }}
                      linkText="Contact Us"
                      className="flex-none btn primary"
                      // style={{ textDecoration: "none" }}
                    /> */}
                  </span>
                </div>
              </div>
            </div>
          </FadeIn>
        </Section>
      </main>
    </Layout>
  );
};

export default IndexPage;
