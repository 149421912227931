import React from "react";

interface LayoutHeaderSectionProps {
  description?: string;
  label?: string;
  subhead?: boolean;
  title: string;
}

const LayoutHeaderSection: React.FC<LayoutHeaderSectionProps> = ({
  description,
  label,
  subhead,
  title,
}) => {
  return (
    <section
      className={`text-center p-gr-3 py-gr-5 md:px-gr-9 md:py-gr-7 lg:px-gr-11 lg:py-gr-7 xl:py-gr-8 `}
    >
      <div className="">
        {label && (
          <div className="tracking-wider uppercase pb-gr-4 text-blue md:text-sm lg:text-base">
            {label}
          </div>
        )}
        <h2 className="text-gr-6 lg:text-gr-7 lg:leading-gr-7">{title}</h2>

        {description && (
          <p
            className={`text-gray-400 text-gr-5
            md:text-base lg:text-xl font-light leading-normal mx-auto max-w-lg`}
          >
            {description}
          </p>
        )}
      </div>
    </section>
  );
};

export default LayoutHeaderSection;
