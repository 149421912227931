import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import Slider from "react-slick";

export default function HomeSlider({ images, config = {} }) {
  var settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: "linear",
    dots: true,
    infinite: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 4,
    speed: 10000,
  };

  return (
    <Slider {...settings} {...config}>
      {images.map((image) => (
        <div key={image.src} className="pl-3">
          <img src={image.src} alt={image.alt} />
        </div>
      ))}
    </Slider>
  );
}
