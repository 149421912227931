import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

interface FadeInProps {
  Component: any;
  children: React.ReactNode;
  transitionDuration?: number;
}

const FadeIn: React.FC<FadeInProps> = ({
  Component,
  transitionDuration = 1,
  children,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const itemVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: transitionDuration },
    },
    hidden: { opacity: 0, scale: 1 },
  };

  return (
    <Component
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={itemVariants}
    >
      {children}
    </Component>
  );
};

export default FadeIn;
