export async function getEvent(id: string) {
  let response = await fetch(`/api/db/events/${id}`);
  if (!response.ok) {
    throw new Error(
      `Error fetching event "${id}". ${response.status} - ${response.statusText}`
    );
  }
  return response.json();
}

export async function getEventProducts(id: string) {
  let response = await fetch(`/api/db/events/${id}/products`);
  if (!response.ok) {
    throw new Error(
      `Error fetching event "${id}". ${response.status} - ${response.statusText}`
    );
  }
  return response.json();
}

export async function getEvents() {
  let response = await fetch("/api/db/events");
  if (!response.ok) {
    throw new Error(
      `Error fetching events: ${response.status} - ${response.statusText}`
    );
  }
  return response.json();
}
