import HomeSlider from "components/home/Slider";

type Props = {};

const sliderimages1 = [
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/66b798ed-4bda-4c04-96b9-a1338a5c17e6/horizontal?updated_at=2023-03-10T15:42:02.51358+00:00",
    alt: "Magic Giant",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/39e50681-e7be-48f7-bd75-dac4cc3e6a21/horizontal?updated_at=2023-03-10T15:02:52.157389+00:00",
    alt: "Teenage Bottlerocket",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/4ab7f7f4-2169-4a3c-bf7a-58d7efc84504/horizontal?updated_at=2023-03-20T21:22:17.851864+00:00",
    alt: "The Black Widows",
  },
  {
    src: "/images/banners/16x9 Local H Banner-1.jpeg",
    alt: "Local H",
  },
  {
    src: "/images/banners/16x9Aristocrats band Photo Banner-1 (1).jpeg",
    alt: "Aristocrats",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/c7d5fd55-e581-4b1e-af3e-9b3a0a096785/horizontal?updated_at=2023-03-10T15:02:00.177824+00:00",
    alt: "PAKT",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/3c467dee-6694-4f4c-bd5b-3161b82fb956/horizontal?updated_at=2023-03-11T22:43:36.006667+00:00",
    alt: "Nebula",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/dfc06837-46b4-4d52-9937-98db16893d8c/horizontal?updated_at=2023-03-10T15:02:33.618039+00:00",
    alt: "Lil Dusty G",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/2c05cb33-af20-47c2-9087-beba562f22ad/horizontal?updated_at=2023-03-10T15:03:57.449177+00:00",
    alt: "Under the Rug",
  },
  {
    src: " https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/0fe7b8c7-e80f-44da-9736-2853d8d6dbb4/horizontal?updated_at=2023-03-24T15:36:54.190563+00:00",
    alt: "The Well",
  },
];

const sliderimages2 = [
  {
    src: "/images/banners/16x 9 Radkey HOMEPAGE-1.jpeg",
    alt: "Radkey",
  },
  {
    src: "/images/banners/16x 9 Riverboat Gamblers-1 (2).jpeg",
    alt: "Riverboat Gamblers",
  },
  {
    src: "/images/banners/16x 9 Beasto Photo 1800x800-1.jpeg",
    alt: "Beasto Blanco",
  },
  {
    src: "/images/banners/16x9 Blues Heaven Fleetwood Mac-1.jpeg",
    alt: "Blues Heaven",
  },

  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/86747b2f-6ab0-4649-991f-eee1eb780488/horizontal?updated_at=2023-03-24T01:39:11.896918+00:00",
    alt: "Jesse Ray and the Carolina Catfish",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/fc527369-c6d1-4bb2-b464-9f51e629b7d6/horizontal?updated_at=2023-03-31T03:01:02.475312+00:00",
    alt: "Khemmis",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/56fe7926-93d9-4d0a-ad80-908e532e4ef0/horizontal?updated_at=2023-04-06T23:41:26.17866+00:00",
    alt: "Dee Oh Gee",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/1bb7bff3-5e3f-4b82-9dc6-e09fc1616b51/horizontal?updated_at=2023-03-10T15:43:42.046002+00:00",
    alt: "El Perro",
  },
  {
    src: "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/events/28d899e6-9df1-4959-8c9b-e60899e5a9b6/horizontal?updated_at=2023-03-10T15:42:38.275239+00:00",
    alt: "Mr. Blotto",
  },
];

function HomeSliderWrapper({ children }) {
  return (
    <div className="relative mb-5">
      {children}
      <div className="absolute bottom-0 w-full h-10 bg-gradient-to-b from-transparent to-grayDarkest"></div>
    </div>
  );
}

const HomeSliderSection = (props: Props) => {
  return (
    <div className="mt-5">
      <HomeSliderWrapper>
        <HomeSlider images={sliderimages1} />
      </HomeSliderWrapper>
      <HomeSliderWrapper>
        <HomeSlider
          images={sliderimages2}
          config={{
            rtl: true,
          }}
        />
      </HomeSliderWrapper>
    </div>
  );
};

export default HomeSliderSection;
